import { useContext, useEffect } from "react"
import { FindMaterial, FindObject } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function DayTote(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/day-tote.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Handle Model
    useEffect(() => {
        let holder_cotton = FindObject("holder_cotton", elements)
        let holder_rainbow = FindObject("holder_rainbow", elements)
        let holder_seatbelt = FindObject("holder_seatbelt", elements)
        if (!holder_cotton || !holder_rainbow || !holder_seatbelt) return
        holder_cotton.visible = false
        holder_rainbow.visible = false
        holder_seatbelt.visible = false
    }, [elements])

    // Handle Strap
    const option_strapColor = props.options.get('strap-color')
    useEffect(() => {
        let hardware_large = FindObject("hardware_large", elements)
        let hardware_small = FindObject("hardware_small", elements)
        let strap_large = FindObject("strap_large", elements)
        let strap_small = FindObject("strap_small", elements)
        let holder_cotton = FindObject("holder_cotton", elements)
        let holder_rainbow = FindObject("holder_rainbow", elements)
        let holder_seatbelt = FindObject("holder_seatbelt", elements)
        if (!hardware_large || !hardware_small || !strap_large || !strap_small || !holder_cotton || !holder_rainbow || !holder_seatbelt) return

        hardware_large.visible = option_strapColor == "seatbelt-black" || option_strapColor == "poly-rainbow"
        hardware_small.visible = option_strapColor != "seatbelt-black" && option_strapColor != "poly-rainbow"
        strap_large.visible = option_strapColor == "seatbelt-black" || option_strapColor == "poly-rainbow"
        strap_small.visible = option_strapColor != "seatbelt-black" && option_strapColor != "poly-rainbow"

        switch (option_strapColor) {
            case "cotton-natural":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0xF2E6CF)
                strap_small.material.roughness = 0.8
                break
            case "cotton-black":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0x38322e)
                strap_small.material.roughness = 0.8
                break
            case "poly-red":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0xd90b15)
                strap_small.material.roughness = 0.6
                break
            case "poly-gray":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0xaaaa9c)
                strap_small.material.roughness = 0.6
                break
            case "poly-blue":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0x2b72bb)
                strap_small.material.roughness = 0.6
                break
            case "poly-orange":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0xe48b15)
                strap_small.material.roughness = 0.6
                break
            case "poly-pink":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0xFF6680)
                strap_small.material.roughness = 0.6
                break
            case "poly-black":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0x38322e)
                strap_small.material.roughness = 0.6
                break
            case "poly-purple":
                strap_small.material = holder_cotton.material
                strap_small.material.color.setHex(0x7041b6)
                strap_small.material.roughness = 0.6
                break
            case "poly-rainbow":
                strap_large.material = holder_rainbow.material
                strap_large.material.color.setHex(0xffffff)
                strap_large.material.roughness = 0.6
                break
            case "seatbelt-black":
                strap_large.material = holder_seatbelt.material
                strap_large.material.color.setHex(0x675c55)
                strap_large.material.roughness = 0.45
                break
        }
    }, [option_strapColor, elements])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial("bag out", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }

        mat = FindMaterial("bag in", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture, elements])
    
    return <ModelElements elements={elements}/>
}