import { Avatar, Button, ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material'
import { AddRounded as AddRoundedIcon, CheckRounded as CheckRoundedIcon } from '@mui/icons-material'
import type BrandingOptionTypeStore from '../../AppData/_stores/BrandingOptionTypeStore'
import { useAppSelector } from '../../Common/_hooks/useAppSelector'
import { type BrandingOptionItem } from '../../UserData/_stores/UserCartStore'
import type UserCartStore from '../../UserData/_stores/UserCartStore'
import { type MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../Common/_hooks/useAppDispatch'
import { DeleteCartItem, UpdateCartItem } from '../../UserData/_actions/UserCartActions'
import { type BrandingOptionStore } from '../../UserData/_stores/BrandingOptionStore'

interface Props {
  type: BrandingOptionTypeStore
  cart: UserCartStore
}

export default function AddStandaloneBrandingOption(props: Props) {
  const dispatch = useAppDispatch()
  const [t] = useTranslation('cart')

  const brandingOptions = useAppSelector(state => state.get('userData').get('brandingOptions')
    .filter(brandingOption => brandingOption.get('id_type') === props.type.get('id')), {
    equalityFn: (a, b) => a.equals(b)
  })
  const cartLoader = useAppSelector(state => state.get('UIData').get('loaders').get('carts'))

  const [opened, setOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const hasItem = useMemo(() => {
    let tmpHasItem = false
    props.cart.get('items').forEach(item => {
      if (item.get('type') !== 'branding_option') return

      const option = brandingOptions.get(String((item as BrandingOptionItem).get('id_branding_option')))

      if (option?.get('id_type') === props.type.get('id')) {
        tmpHasItem = true
        return false
      }
    })

    return tmpHasItem
  }, [props.cart, props.type, brandingOptions])

  const onRemove = useCallback(() => {
    props.cart.get('items').forEach(item => {
      if (item.get('type') !== 'branding_option') return

      const option = brandingOptions.get(String((item as BrandingOptionItem).get('id_branding_option')))

      if (option?.get('id_type') === props.type.get('id')) {
        const fd = new FormData()
        fd.append('id_cart', String(props.cart.get('id')))
        fd.append('id', String(item.get('id')))
        fd.append('quickDeleteFromCart', '1')

        dispatch(DeleteCartItem(fd))
      }
    })
  }, [props.cart, props.type, brandingOptions])

  const onMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget)
    setOpened(true)
  }, [])

  const onMenuClose = useCallback(() => {
    setOpened(false)
  }, [])

  const renderOption = useCallback((brandingOption: BrandingOptionStore) => {
    return <MenuItem
      key={brandingOption.get('id')}
      onClick={() => {
        const fd = new FormData()
        fd.append('cart_id', String(props.cart.get('id')))
        fd.append('quantity', '1')
        fd.append('type', 'branding_option')
        fd.append('id_branding_option', String(brandingOption.get('id')))

        dispatch(UpdateCartItem(fd).set({
          onSuccess: () => {
            const id = 'branding_option-'+brandingOption.get('id_type');
            const content_ids = [id];
            const contents: { id: string, quantity: number }[] = [{
              id: id,
              quantity: 1,
            }]

            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            window.fbq && window.fbq('track', 'AddToCart', {
              content_type: 'product',
              content_ids: content_ids,
              contents: contents,
            })
          }
        }))
        setOpened(false)
      }}
    >
      <ListItemAvatar>
        <Avatar src={brandingOption.get('file_url')} />
      </ListItemAvatar>
      <ListItemText>{brandingOption.get('name')}</ListItemText>
    </MenuItem>
  }, [props.cart.get('id')])

  // Don't display if user doesn't have any
  if (brandingOptions.count() === 0) return null

  return <>
    <Button
      variant={hasItem ? 'contained' : 'dashed'}
      color={hasItem ? 'primary' : 'secondary'}
      startIcon={hasItem ? <CheckRoundedIcon /> : <AddRoundedIcon />}
      onClick={hasItem ? onRemove : onMenuOpen}
      disabled={cartLoader}
    >{hasItem ? t('{{name}} added', { name: props.type.get('name') }) : t('Add {{name}}', { name: props.type.get('name') })}</Button>

    <Menu
      open={opened}
      onClose={onMenuClose}
      anchorEl={anchorEl}
    >
      { brandingOptions.valueSeq().map(renderOption)}
    </Menu>
  </>
}
