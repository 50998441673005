import { useContext, useEffect } from "react"
import { DoubleSide } from "three"
import { FindMaterial } from "../../Common/Utils/ThreeTools"
import ModelElements from "../ModelElements"
import { ProductModelProps } from "../ModelViewerProduct"
import ProductTextureContext from "../_contexts/ProductTextureContext"
import useGLB from "../_hooks/useGLB"

export default function OrigamiTote(props: ProductModelProps) {
    const url = new URL('@resources/3d-models/glb/origami-tote.glb', import.meta.url);
    const model = useGLB(url, props.viewerId)
    const elements = model.scene.children
    const [productTexture] = useContext(ProductTextureContext)

    // Handle Inside
    useEffect(() => {
        let mat = FindMaterial("bag out", elements)
        if(mat) mat.side = DoubleSide
        
        mat = FindMaterial("bag in", elements)
        if(mat){
            mat.transparent = true
            mat.opacity = 0.5
        }
    }, [elements])

    // Handle Edge
    const option_threadColor = props.options.get('thread-color') ?? 'black'
    useEffect(() => {
        let mat = FindMaterial("bag edge", elements)
        if(mat) mat.color.setHex({
            'white': 0xeeeeee,
            'light-grey': 0xa4a4a4,
            'dark-grey': 0x555555,
            'black': 0x2a2a2a
        }[option_threadColor] as number)
    }, [option_threadColor])

    // Handle Strap
    const option_strapColor = props.options.get('strap-color')
    useEffect(() => {
        let strap_in = FindMaterial("Strap In", elements)
        let strap_out = FindMaterial("Strap Out", elements)
        if(strap_in && strap_out) {
            switch (option_strapColor) {
                case 'black':
                    strap_in.color.setHex(0x808080)
                    strap_out.color.setHex(0x12120D)
                    break;
                case 'brown':
                    strap_in.color.setHex(0x99705C)
                    strap_out.color.setHex(0x7B4A30)
                    break;
                case 'navy':
                    strap_in.color.setHex(0x4b5773)
                    strap_out.color.setHex(0x2e374d)
                    break;
            }
        }
    }, [option_strapColor, elements])

    // Apply lab texture
    useEffect(() => {
        if (!elements) return
        let mat = FindMaterial("bag out", elements)
        if(mat){
            mat.map = productTexture
            mat.map.flipY = false
            mat.map.needsUpdate = true
        }
    }, [productTexture])
    
    return <ModelElements elements={elements}/>
}